import { Route, Routes } from "react-router";
import HomePage from "./Pages/Home";
import { useEffect, useState } from 'react'
import ThematiquePage from "./Pages/Thematique";
import { useMediaQuery } from "./hooks";
import Image from "./Components/Image";
import Logo from "./Components/Logo";
import Modal from "react-modal/lib/components/Modal";


export default function App() {
    const [showWelcome, setShowWelcome] = useState(process.env.NODE_ENV === 'production')

    useEffect(() => {
        Modal.setAppElement('body')
    }, [])

    return (
        showWelcome
            ? <MainPopup onClick={() => setShowWelcome(false)} />
            : <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/thematiques/:_id" element={<ThematiquePage />} />
            </Routes>
    )
}

function MainPopup({ onClick }) {
    const isMobile = useMediaQuery('(max-width: 500px)')

    return (
        isMobile
            ?
            <div className="fixed inset-0 bg-white z-20 w-screen h-screen flex flex-col items-center gap-8 p-4 overflow-y-auto">
                <div className="w-3/5"><Logo /></div>
                <Image className="w-3/5" name="static/4fe506a3-e3bb-4b3a-83d5-accb596edf0a.jpg" />
                <div className="text-center">
                    <h1 className="uppercase">Jacques Courtens</h1>
                    <h2>« Peintre créateur de mouvement »</h2>
                </div>
                <div className="text-justify">
                    <p className="mb-4">Observateur assidu des maîtres de l'Art occidental depuis la Renaissance, <strong>Jacques Courtens</strong> reste l'un des grands de la figuration de notre temps. Il a sa propre technique du trait pour délimiter l'espace tout en lui insufflant le vibrato de la vie – aussi bien dans les paysages balayés par l'air que dans les portraits qui génèrent l'élan de l'âme – et il a pour la couleur une passion effrénée.</p>
                    <p className="mb-4"><strong className="italic">« Vivre le jour, toucher la nuit, vivre son rêve à l'infini »</strong>, <br />disait Jacques Courtens dans un de ses écrits.</p>
                    <p className="mb-4">La plume et le pinceau produisent l'évidence d'une énorme créativité. Il ouvre aux jeunes artistes le champ de la technique et de la représentation à un grand tournant de l'histoire de l'art.</p>
                </div>
                <div onClick={onClick} style={{ backgroundColor: "#1a5276" }} className="mx-auto w-40 p-2 text-center text-white rounded-md cursor-pointer hover:bg-blue-600 transition">Accéder au site</div>
            </div>
            :
            <div className="fixed top-0 left-0 bg-white z-20 h-screen w-screen flex justify-center items-center">
                <div className="flex gap-20 h-5/6 2xl:w-8/12 w-11/12 justify-center">
                    <Image className="max-h-full w-auto" name="static/4fe506a3-e3bb-4b3a-83d5-accb596edf0a.jpg" />
                    <div className="flex-1 flex flex-col gap-8 items-center h-full">
                        <div className="w-8/12"><Logo /></div>
                        <div className="text-center">
                            <h1 className="uppercase">Jacques Courtens</h1>
                            <h2>« Peintre créateur de mouvement »</h2>
                        </div>
                        <div className="text-justify">
                            <p>Observateur assidu des maîtres de l'Art occidental depuis la Renaissance, <strong>Jacques Courtens</strong> reste l'un des grands de la figuration de notre temps. Il a sa propre technique du trait pour délimiter l'espace tout en lui insufflant le vibrato de la vie – aussi bien dans les paysages balayés par l'air que dans les portraits qui génèrent l'élan de l'âme – et il a pour la couleur une passion effrénée.</p>
                            <p><strong className="italic">« Vivre le jour, toucher la nuit, vivre son rêve à l'infini »</strong>, <br />disait Jacques Courtens dans un de ses écrits.</p>
                            <p>La plume et le pinceau produisent l'évidence d'une énorme créativité. Il ouvre aux jeunes artistes le champ de la technique et de la représentation à un grand tournant de l'histoire de l'art.</p>
                        </div>
                        <div className="flex-1 flex justify-center overflow-hidden">
                            <div>
                                <Image className="h-full w-auto" name="static/28ed2f76-fe2c-474a-a538-7a7b14af8242.jpg" />
                            </div>
                            <div className="flex items-center">
                                <div onClick={onClick} style={{ backgroundColor: "#1a5276" }} className="mx-auto w-40 p-2 text-center text-white rounded-md cursor-pointer hover:bg-blue-600 transition">Accéder au site</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    )
}